(function() {
    'use strict';

    const openLanguageNavigationPopover = (languageNavigationButton, languageNavigationDropdown) => {
        languageNavigationButton.setAttribute('aria-expanded', 'true');
        languageNavigationDropdown.show();

        // Close popover on Escape
        document.addEventListener('keyup', (event) => {
            if (event.key === 'Escape') {
                languageNavigationDropdown.close();
                languageNavigationButton.focus();
            }
        }, false);

        // Close popover when clicked outside
        document.addEventListener('click', (event) => {
            if (!event.target.closest('.cmp-language-navigation')) {
                languageNavigationDropdown.close();
                languageNavigationButton.focus();
            }
        }, false);
    };

    const initLanguageNavigation = () => {
        const languageNavigation = document.querySelector('.cmp-language-navigation');
        const languageNavigationButton = document.getElementById('language-navigation-button');
        const languageNavigationDropdown = document.getElementById('language-navigation-dropdown');

        if (languageNavigation && languageNavigationButton && languageNavigationDropdown) {
            const languageNavigationLinks = languageNavigationDropdown.querySelectorAll('a');

            languageNavigationButton.addEventListener('click', () => {
                if (languageNavigationButton.getAttribute('aria-expanded') === 'false') {
                    openLanguageNavigationPopover(languageNavigationButton, languageNavigationDropdown);
                } else {
                    languageNavigationDropdown.close();
                }
            });

            languageNavigation.addEventListener('focusout', (event) => {
                // If focus is in the language navigation dropdown or trigger do nothing
                if (languageNavigation.contains(event.relatedTarget)) {
                    return;
                }
                // If focus leaves close the popover
                languageNavigationDropdown.close();
            });

            languageNavigationDropdown.addEventListener('close', () => {
                languageNavigationButton.setAttribute('aria-expanded', 'false');
            });

            [...languageNavigationLinks].forEach(languageSwitchLink => {
                languageSwitchLink.addEventListener('click', () => {
                    languageNavigationDropdown.close();
                }, false);
            });
        }
    };

    if (document.readyState !== 'loading') {
        initLanguageNavigation();
    } else {
        document.addEventListener('DOMContentLoaded', initLanguageNavigation);
    }

}());
