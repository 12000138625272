(function() {
    'use strict';

    const initNavigationMobile = () => {
        const mobileMenuOpenIcon = document.querySelector('.js-mobile-menu-open');
        const mobileMenuCloseIcon = document.querySelector('.js-mobile-menu-close');
        const mobileMenu = document.querySelector('.js-mobile-menu');

        if (mobileMenuOpenIcon) {
            mobileMenuOpenIcon.addEventListener('click', () => {
                mobileMenu.showModal();
            });
        }

        if (mobileMenuCloseIcon) {
            mobileMenuCloseIcon.addEventListener('click', () => {
                mobileMenu.close();
            });
        }

        // close menu when clicking outside of dialog
        if (mobileMenu) {
            // eslint-disable-next-line no-shadow
            mobileMenu.addEventListener('click', ({ target: mobileMenu }) => {
                if (mobileMenu.nodeName === 'DIALOG') {
                    mobileMenu.close();
                }
            });
        }
    };

    if (document.readyState !== 'loading') {
        initNavigationMobile();
    } else {
        document.addEventListener('DOMContentLoaded', initNavigationMobile);
    }

}());
