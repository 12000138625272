// == Stylesheets
import './theme.scss';

// == Base
import '/src/site/base/navigation/language-navigation';
import '/src/site/base/navigation/navigation-mobile';

// == Cookie - Dynamic Imports
import('/src/components/cookie/cookie-modal/cookie-modal').then(module => {
    module.default();
});

const importIfDomReady = () => {
    if (document.readyState !== 'loading') {
        if (document.querySelector('.js-cookie-preferences')) {
            import('/src/components/cookie/cookie-preferences/cookie-preferences').then(module => module.default());
        }

        if (document.querySelector('.js-content-carousel')) {
            import('/src/components/content-carousel/content-carousel').then(module => module.default());
        }

        if (document.querySelector('.js-inspiration-grid-card')) {
            import('/src/components/inspiration-block/inspiration-grid').then(module => module.default());
        }

        if (document.querySelector('.js-picture-carousel')) {
            import('/src/components/picture-carousel/picture-carousel').then(module => module.default());
        }

        if (document.querySelector('.js-dealer-quote')) {
            import('/src/components/dealer-quote/dealer-quote.controller').then(module => module.default());
        }

        if (document.querySelector('.js-embed-youtube')) {
            import('/src/components/embed/embed-youtube').then(module => module.default());
        }

        if (document.querySelector('.js-embed-vimeo')) {
            import('/src/components/embed/embed-vimeo').then(module => module.default());
        }
    } else {
        document.addEventListener('DOMContentLoaded', importIfDomReady);
    }
};

importIfDomReady();
